import React from 'react'

import { useRequest } from 'ahooks'

import styles from './UpdateUxAttributeTool.module.scss'

export default function UpdateUxAttributeTool({
  csrfToken,
}: {
  csrfToken: string
}) {
  const [segmentId, setSegmentId] = React.useState('')
  const [segmentName, setSegmentName] = React.useState('')
  const [error, setError] = React.useState('')
  const [isConfirming, setIsConfirming] = React.useState(false)

  const { run: runGetSegmentName } = useRequest(
    () =>
      fetch(`/api/customerio/get-segment-name/?id=${segmentId}`).then((res) => {
        if (res.ok) {
          return res.json()
        }
        throw new Error(`${res.status} - ${res.statusText}`)
      }),
    {
      onError: (e: Error) => {
        setError(e.message)
      },
      onSuccess: (data: any) => {
        setSegmentName(data.name)
        setIsConfirming(true)
      },
      manual: true,
    },
  )

  const { run: runUpdateSegment } = useRequest(
    () =>
      fetch(`/api/customerio/update-segment/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
        body: JSON.stringify({ id: segmentId }),
      }).then((res) => {
        if (!res.ok) {
          throw new Error(`${res.status} - ${res.statusText}`)
        }
      }),
    {
      manual: true,
      onError: (e) => {
        setError(e.message)
      },
      onSuccess: () => {
        setError('Success!')
        setSegmentName('')
        setIsConfirming(false)
        setSegmentId('')
      },
    },
  )

  if (isConfirming) {
    return (
      <div>
        <p>
          Confirm update for segment: {segmentName} ({segmentId})?
        </p>
        <button onClick={runUpdateSegment}>Confirm</button>
        <button onClick={() => setIsConfirming(false)}>Cancel</button>
        {error && <p className={styles.error}>{error}</p>}
      </div>
    )
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        runGetSegmentName()
      }}
    >
      <input
        type="text"
        value={segmentId}
        onChange={(e) => setSegmentId(e.target.value)}
        placeholder="Enter segment ID"
      />
      <button type="submit">Submit</button>
      {error && <p className={styles.error}>{error}</p>}
    </form>
  )
}
