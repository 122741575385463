import { addComponentToDOMById } from '$serverSrc/utils/component'

import EnterprisePage from './components/EnterprisePage'
import HomePageMetrics from './components/HomePageMetrics'
import ImpactMetricsDebug from './components/ImpactMetricsDebug'
import IssueResolution from './components/IssueResolution'
import MetricsDataUpload from './components/MetricsDataUpload'
import MetricsKeyFunnelTable from './components/MetricsKeyFunnelTable'
import MetricsKpis from './components/MetricsKpis'
import NotificationPreview from './components/NotificationPreview'
import RecommendationCardPreview from './components/RecommendationCardPreview'
import CreateNewUserInterviewSegmentTool from './components/Tools/CustomerioUserInterview/CreateNewUserInterviewSegmentTool'
import SegmentBrowser from './components/Tools/CustomerioUserInterview/SegmentBrowser'
import UpdateUxAttributeTool from './components/Tools/CustomerioUserInterview/UpdateUxAttributeTool'
import UsersPage from './components/UsersPage'

import './index.scss'

class App {
  constructor() {
    addComponentToDOMById(
      CreateNewUserInterviewSegmentTool,
      'CustomerioCreateNewUserInterviewSegmentTool',
    )
    addComponentToDOMById(SegmentBrowser, 'CustomerioSegmentBrowser')
    addComponentToDOMById(
      UpdateUxAttributeTool,
      'CustomerioUpdateUxAttributeTool',
    )
    addComponentToDOMById(EnterprisePage, 'Enterprise')
    addComponentToDOMById(HomePageMetrics, 'HomePageMetrics')
    addComponentToDOMById(ImpactMetricsDebug, 'ImpactMetricsDebug')
    addComponentToDOMById(IssueResolution, 'IssueResolution')
    addComponentToDOMById(MetricsDataUpload, 'MetricsDataUpload')
    addComponentToDOMById(MetricsKeyFunnelTable, 'MetricsKeyFunnelTable')
    addComponentToDOMById(MetricsKpis, 'MetricsKpis')
    addComponentToDOMById(
      RecommendationCardPreview,
      'RecommendationCardPreview',
    )
    addComponentToDOMById(NotificationPreview, 'NotificationPreview')
    addComponentToDOMById(UsersPage, 'Users')
  }
}

// Always export a 'new' instance so it immediately evokes:
export default new App()
