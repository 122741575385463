import React, { useState } from 'react'

import { useRequest } from 'ahooks'

import styles from './CreateNewUserInterviewSegmentTool.module.scss'

function getNextMondayDateString() {
  const today = new Date()
  const nextMonday = new Date(today)
  nextMonday.setDate(today.getDate() + ((8 - today.getDay()) % 7))

  const monthString =
    nextMonday.getMonth() < 9
      ? `0${nextMonday.getMonth() + 1}`
      : `${nextMonday.getMonth() + 1}`
  const dateString =
    nextMonday.getDate() < 10
      ? `0${nextMonday.getDate()}`
      : `${nextMonday.getDate()}`
  return `${nextMonday.getFullYear()}${monthString}${dateString}`
}

export default function CreateNewUserInterviewSegmentTool({
  csrfToken,
}: {
  csrfToken: string
}) {
  const [formData, setFormData] = useState({
    new_segment_name: `User Research Outreach - Incentivized - ${getNextMondayDateString()}`,
    num_people: '300',
    base_segment_id: '0',
  })
  const [error, setError] = useState<string | null>(null)
  const { run } = useRequest(
    () =>
      fetch('/api/customerio/create-new-user-interview-segment/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
        body: JSON.stringify({
          newSegmentName: formData.new_segment_name,
          numPeople: parseInt(formData.num_people, 10),
          baseSegmentId: parseInt(formData.base_segment_id, 10),
        }),
      }).then((res) => {
        if (!res.ok) {
          throw new Error(`${res.status} - ${res.statusText}`)
        }
      }),
    {
      onSuccess: () => {
        setError('Success!')
      },
      onError: (e) => {
        setError(e.message)
      },
      manual: true,
    },
  )

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    run()
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <table>
          <tr>
            <label htmlFor="new_segment_name">
              <td>New segment name</td>
              <td>
                <input
                  type="text"
                  id="new_segment_name"
                  name="new_segment_name"
                  value={formData.new_segment_name}
                  onChange={handleChange}
                  required
                  className={styles.inputField}
                />
              </td>
            </label>
          </tr>

          <tr>
            <label htmlFor="num_people">
              <td>Number of people</td>
              <td>
                <input
                  type="number"
                  id="num_people"
                  name="num_people"
                  value={formData.num_people}
                  onChange={handleChange}
                  required
                  min="1"
                  className={styles.inputField}
                />
              </td>
            </label>
          </tr>
          <tr>
            <label htmlFor="base_segment_id">
              <td>Base segment id</td>
              <td>
                <input
                  type="number"
                  id="base_segment_id"
                  name="base_segment_id"
                  value={formData.base_segment_id}
                  onChange={handleChange}
                  required
                  min="1"
                  className={styles.inputField}
                />
              </td>
            </label>
          </tr>
        </table>
        <button type="submit">Create Segment</button>
        {error && <div className={styles.error}>{error}</div>}
      </form>
    </div>
  )
}
