import React, { useState } from 'react'

import { useRequest } from 'ahooks'

import styles from './SegmentBrowser.module.scss'

export default function SegmentBrowser() {
  const [error, setError] = useState<string | null>(null)
  const { data, loading, refresh } = useRequest(
    () =>
      fetch('/api/customerio/list-segments/').then((res) => {
        if (res.ok) {
          return res.json()
        }
        throw new Error(`${res.status} - ${res.statusText}`)
      }),
    {
      onError: (e) => {
        setError(e.message)
      },
    },
  )

  if (loading || !data) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div className={styles.error}>Error: {error}</div>
  }

  return (
    <div>
      <table>
        <th>
          <td>ID</td>
          <td>Name</td>
        </th>
        {(data?.segments || []).map((segment: any, ix: number) => (
          <tr
            key={segment.id}
            className={ix % 2 === 0 ? styles.evenRow : styles.oddRow}
          >
            <td>{segment.id}</td>
            <td>{segment.name}</td>
          </tr>
        ))}
      </table>
      <button onClick={refresh}>Refresh</button>
    </div>
  )
}
